import React, { useEffect, useState } from "react";
import Head from "../../../Components/Head";
import { db } from "../../../Components/Firebase";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../Components/Loading"; 
import '../../../Styles/Pages/Manager/Students/ViewClasses.css'

function ViewClasses() {
    const { userid, schoolId, layerid } = useParams();
    const [classes, setClasses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const nav = useNavigate()

    useEffect(() => {
        const fetchClasses = async () => {
            try {
                const classesSnapshot = await db
                    .collection('Schools')
                    .doc(schoolId)
                    .collection('Layers')
                    .doc(layerid)
                    .collection('Classes')
                    .get();
                
                const classesData = await Promise.all(classesSnapshot.docs.map(async (doc) => {
                    const classData = {
                        id: doc.id,
                        ...doc.data(),
                    };
                    
                    if (classData.manager) {
                        const teacherDoc = await db
                            .collection('Teachers')
                            .doc(classData.manager)
                            .get();

                        if (teacherDoc.exists) {
                            classData.managerData = teacherDoc.data();
                        } else {
                            classData.managerData = null;
                        }
                    }

                    return classData;
                }));
                
                setClasses(classesData);
                setLoading(false);
            } catch (err) {
                setError('שגיאה בטעינת הכיתות');
                setLoading(false);
            }
        };

        fetchClasses();
    }, [userid, schoolId, layerid]);

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <React.Fragment>
            <Head />

            <div className="ViewClasses">
                <div className="classes-list">
                    {classes.map((classItem) => (
                        <div key={classItem.id} className="class-item">
                            <h1>{classItem.name}</h1>
                            <p>{classItem.managerData ? classItem.managerData.firstName + " " + classItem.managerData.lastName : 'מנהל לא נמצא'} <img src={classItem.managerData.imageUrl} alt="Managerimg"/></p>

                            <button className="MSCB" onClick={() => nav(`/Manager/${userid}/${schoolId}/ManageStudents/${layerid}/Classes/${classItem.id}`)}>לצפות בכיתה</button>
                        </div>
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
}

export default ViewClasses;

import React, { useRef } from 'react'
import { FaBars, FaTimes } from 'react-icons/fa'
import Logo from '../Images/Logo.webp'
//import { useNavigate } from 'react-router-dom'
import '../Styles/Components/NavBar.css'

const NavBar = () => {

    const navRef = useRef()
    //const nav = useNavigate()


    const showNavbar = () => {
        navRef.current.classList.toggle("responsive_nav")
    }

    return(
        <header>
        <a href="/" style={{width: 'auto', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <img
          src={Logo}
          alt="header logo"
          onContextMenu={(e) => e.preventDefault()}
          onDragStart={(e) => e.preventDefault()}
        />
        </a>

        <nav className="navbar" ref={navRef}>
          <div className="innav">
            <img
              src={Logo}
              alt="header logo1"
              onContextMenu={(e) => e.preventDefault()}
              onDragStart={(e) => e.preventDefault()}
            />
            <button onClick={showNavbar} className="navbtn navhide">
              <FaTimes color="#1A58B6" />
            </button>
          </div>
          <button >שירותים</button>
          <button >מי אנחנו</button>
          <button >לפנות אלינו</button>
        </nav>
        <button onClick={showNavbar} className="navbtn navshow">
          <FaBars color="#1A58B6" />
        </button>

      </header>
    )
}
export default NavBar;
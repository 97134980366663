import { Route, Routes } from "react-router-dom";

//Pages
import Landing from "./Pages/Landing";
import CreateAccount from "./Pages/Auth/CreateAccount";
import VerifyEmail from "./Pages/Auth/VerifyEmail";
import Loading from "./Components/Loading";
import SchoolCode from "./Pages/Auth/SchoolCode";
import TeacherRoll from "./Pages/Auth/TeacherRoll";
import CheckUser from "./Pages/Auth/CheckUser";

//Manager
import ManagerHome from "./Pages/Manager/ManagerHome";
import ManageStudents from "./Pages/Manager/Students/ManageStudents";
import ViewClasses from "./Pages/Manager/Students/ViewClasses";
import ClassData from "./Pages/Manager/Students/ClassData";
import ViewDropouts from "./Pages/Manager/ViewDropouts";
import ViewExitPre from "./Pages/Manager/ViewExitPre";
import AddStudentsFile from "./Functions/Manager/Data/AddStudentsFile";
import ViewTeachers from "./Pages/Manager/ViewTeachers";
import TeacherProfileView from "./Pages/Manager/TeacherProfileView";

//Teacher
import TeacherHome from "./Pages/Teacher/TeacherHome";
import CurrentStudentsList from "./Pages/Teacher/CurrentStudentsList";

//School
import SchoolMain from "./Pages/Manager/School/SchoolMain";
import Layers from "./Pages/Manager/School/Layers";
import Classes from "./Pages/Manager/School/Classes";
import PluginSettings from "./Pages/Manager/School/PluginSettings";
import MessagesSettings from "./Pages/Manager/School/MessagesSettings";


function App () {
  
  return (

    <Routes>

      <Route path="/" element={<Landing/>}/>
      <Route path="/L" element={<Loading/>}/>
      <Route path="/CreateAccount" element={<CreateAccount/>}/>
      <Route path="/VerifyEmail" element={<VerifyEmail/>}/>
      <Route path="/SchoolCode/:userid" element={<SchoolCode/>}/>
      <Route path="/SchoolCode/:userid/TeacherRoll" element={<TeacherRoll/>}/>
      <Route path="/CheckUser/:userid" element={<CheckUser/>}/>
      
       {/* Manager */}

       <Route path="/Manager/:userid" element={<ManagerHome/>}/>
       <Route path="/Manager/:userid/ViewTeachers" element={<ViewTeachers/>}/>
       <Route path="/Manager/:userid/:teacherid" element={<TeacherProfileView/>}/>
       <Route path="/Manager/:userid/:schoolId/ManageStudents" element={<ManageStudents/>}/>
       <Route path="/Manager/:userid/:schoolId/Dropouts" element={<ViewDropouts/>}/>
       <Route path="/Manager/:userid/:schoolId/ExitPre" element={<ViewExitPre/>}/>
       <Route path="/Manager/:userid/:schoolId/ManageStudents/:layerid/Classes" element={<ViewClasses/>}/>
       <Route path="/Manager/:userid/:schoolId/ManageStudents/:layerid/Classes/:classid" element={<ClassData/>}/>
       <Route path="/Manager/:userid/:schoolId/ManageStudents/:layerid/Classes/:classid/AddFile" element={<AddStudentsFile/>}/>

       {/* Teacher */}

       <Route path="/Teacher/:userid" element={<TeacherHome/>}/>
       <Route path="/Teacher/:userid/StudentsList" element={<CurrentStudentsList/>}/>

       {/* School */}

       <Route path="/Manager/:userid/SchoolMain/:schoolid" element={<SchoolMain/>}/>
       <Route path="/Manager/:userid/SchoolMain/:schoolid/Layers" element={<Layers/>}/>
       <Route path="/Manager/:userid/SchoolMain/:schoolid/Layers/:layerid" element={<Classes/>}/>
       <Route path="/Manager/:userid/SchoolMain/:schoolid/PluginSettings" element={<PluginSettings/>}/>
       <Route path="/Manager/:userid/SchoolMain/:schoolid/MessagesSettings" element={<MessagesSettings/>}/>

    </Routes>
  )
}

export default App
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../Components/Firebase";
import Vec4 from '../../Images/Vector4.webp';

function VerifyNumber() {
    const [errorMessage, setErrorMessage] = useState("");
    const nav = useNavigate();

    const checkEmailVerification = async () => {
        const user = auth.currentUser;
        await user.reload();
        if (user.emailVerified) {
            nav(`/SchoolCode/${user.uid}`)
        } else {
            setErrorMessage("הדוא\"ל שלך לא אומת. אנא אמת את חשבונך.");
        }
    };

    useEffect(() => {
        const interval = setInterval(checkEmailVerification, 5000);
        return () => clearInterval(interval);
    }, []);

    return (
        <React.Fragment>
            <div className="CAM">
                <img src={Vec4} alt="Vec" />
                <div className="Userinfo">
                    <p dir="rtl" style={{ color: '#007DFF' }}>שלחנו לך קישור לדוא"ל שלך, אנא אמת את חשבונך</p>
                    <button style={{ background: '#007DFF' }} onClick={checkEmailVerification}>הבא</button>
                    {errorMessage && <p dir="rtl" style={{ color: 'red' }}>{errorMessage}</p>}
                </div>
            </div>
        </React.Fragment>
    );
}

export default VerifyNumber;

import React from "react";
import LogoA from '../Images/LogoA.gif'

import '../Styles/Components/Loading.css'

function Loading () {

    return (

        <div className="Loading">

            <img src={LogoA} alt="Logo animation" onContextMenu={(e) => e.preventDefault()}
              onDragStart={(e) => e.preventDefault()}/>
            
        </div>
    )
}

export default Loading
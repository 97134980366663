import { useState, useEffect } from "react";
import { db } from "../../../Components/Firebase";
import { useParams, useNavigate } from "react-router-dom";
import "../../Styles/Manager/Data/FetchTeachers.css";
import Loading from "../../../Components/Loading";
import Logo from '../../../Images/Logo.webp';

function FetchTeachers() {
  const [teachers, setTeachers] = useState([]);
  const { userid } = useParams();
  const nav = useNavigate();

  useEffect(() => {
    const fetchUserSchoolAndTeachers = async () => {
      try {
        const userDoc = await db.collection("Teachers").doc(userid).get();
        
        if (userDoc.exists) {
          const userData = userDoc.data();
          const schoolname = userData.schoolName;

          const teachersCollection = await db
            .collection("Teachers")
            .where("schoolName", "==", schoolname)
            .get();

          const teachersList = teachersCollection.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          setTeachers(teachersList);
        } else {
          console.log("User not found");
        }
      } catch (error) {
        console.error("Error fetching user or teachers: ", error);
      }
    };

    fetchUserSchoolAndTeachers();
  }, [userid, teachers]);

  const getStatusClass = (status) => {
    switch (status) {
      case "זמין":
        return "status-available";
      case "בשיעור":
        return "status-in-class";
      case "לא זמין":
        return "status-not-available";
      default:
        return "status-unknown";
    }
  };

  return (
    <div className="teachers-container">
      {teachers.length > 0 ? (
        <div className="teacher-cards" dir="rtl">
          {teachers.map((teacher) => (
            <div key={teacher.id} className="teacher-card">
              <img 
                src={teacher.imageUrl || Logo} 
                alt="Teacher" 
                className="teacher-image" 
              />
              <div className="teacher-info">
                <h3>{teacher.firstName} {teacher.lastName}</h3>
                <p><strong>סטטוס:</strong> <span className={getStatusClass(teacher.status)}>{teacher.status}</span></p>
                <button onClick={() => { nav(`/Manager/${userid}/${teacher.id}`) }}>צפה בפרופיל</button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default FetchTeachers;

import React, { useState } from "react";
import { db } from "../../../Components/Firebase";
import "../../Styles/Manager/Data/AddNewSpecialization.css";

function AddNewSpecialization({ onClose, schoolid }) {
    const [specializationName, setSpecializationName] = useState("");

    const handleSave = async () => {
        if (specializationName.trim()) {
            try {
                await db.collection("Schools").doc(schoolid).collection("Specializations").add({
                    name: specializationName,
                    createdAt: new Date(),
                });
                console.log("Specialization added:", specializationName);
                onClose();
            } catch (error) {
                console.error("Error adding specialization: ", error);
            }
        }
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>הוסף התמחות חדשה</h2>
                <input
                    type="text"
                    placeholder="שם ההתמחות"
                    value={specializationName}
                    onChange={(e) => setSpecializationName(e.target.value)}
                    className="modal-input"
                />
                <div className="modal-actions">
                    <button className="modal-button cancel" onClick={onClose}>ביטול</button>
                    <button className="modal-button save" onClick={handleSave}>שמור</button>
                </div>
            </div>
        </div>
    );
}

export default AddNewSpecialization;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../Components/Firebase";
import '../../../Functions/Styles/Manager/Data/StudentsInClassCount.css'

function StudentsInClassCount() {
    const { userid, schoolId, classid } = useParams();
    const [studentCount, setStudentCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchStudentCount = async () => {
            try {
                const studentsCollection = collection(db, "Schools", schoolId, "Students");
                const q = query(studentsCollection, where("class", "==", classid));
                const studentsSnapshot = await getDocs(q);
                setStudentCount(studentsSnapshot.docs.length);
            } catch (error) {
                console.error("Error fetching student count: ", error);
                setError("Error loading student count.");
            } finally {
                setLoading(false);
            }
        };

        fetchStudentCount();
    }, [userid, schoolId, classid, studentCount]);

    if (loading) return <p>טוען...</p>;
    if (error) return <h1>{error}</h1>;

    return (
        <React.Fragment>
                <h1 className="SC">{studentCount}</h1>
        </React.Fragment>
    );
}

export default StudentsInClassCount;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../Components/Firebase";
import Head from "../../Components/Head";
import Loading from "../../Components/Loading";
import "../../Styles/Pages/Manager/ViewExitPre.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";

function ViewExitPre() {
    const { schoolId } = useParams();
    const [exitPreDocs, setExitPreDocs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [filteredDocs, setFilteredDocs] = useState([]);

    useEffect(() => {
        const fetchExitPreDocs = async () => {
            try {
                const snapshot = await db.collection("Schools")
                    .doc(schoolId)
                    .collection("ExitPre")
                    .get();

                const docsData = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                setExitPreDocs(docsData);
                setFilteredDocs(docsData);
            } catch (err) {
                setError("שגיאה במהלך שליפת המסמכים.");
            } finally {
                setLoading(false);
            }
        };

        fetchExitPreDocs();
    }, [schoolId]);

    useEffect(() => {
        if (selectedDate) {
            const filtered = exitPreDocs.filter(doc => {
                const docDate = new Date(doc.date);
                const formattedDocDate = format(docDate, "dd/MM/yyyy");
                const formattedSelectedDate = format(selectedDate, "dd/MM/yyyy");
                return formattedDocDate === formattedSelectedDate;
            });
            setFilteredDocs(filtered);
        }
    }, [selectedDate]);

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <p className="error-message">{error}</p>;
    }

    return (
        <React.Fragment>
            <Head />
            <div className="date-picker-container">
                <DatePicker
                    selected={selectedDate}
                    onChange={handleDateChange}
                    dateFormat="dd/MM/yyyy"
                    className="date-picker"
                />
            </div>
            <div className="exit-pre-container" dir="rtl">
                {filteredDocs.length > 0 ? (
                    <div className="exit-pre-docs">
                        {filteredDocs.map((doc) => (
                            <div key={doc.id} className="exit-pre-item">
                                <p>{doc.studentName}</p>
                                <p>{doc.date}</p>
                                <p>{doc.time}</p>
                                <div className="signature-preview">
                                    <img src={doc.signature} alt="חתימה" />
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p>לא נמצאו מסמכי אישור יציאה</p>
                )}
            </div>
        </React.Fragment>
    );
}

export default ViewExitPre;

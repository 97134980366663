import React from "react";
import Head from "../../Components/Head";
import FetchTeacherData from "../../Functions/Manager/Data/FetchTeacherData";

function TeacherProfileView () {

    return (

        <React.Fragment>

            <Head/>

            <FetchTeacherData/>

        </React.Fragment>
    )
}

export default TeacherProfileView
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../Components/Firebase";
import Head from "../../Components/Head";
import Loading from "../../Components/Loading";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../../Styles/Pages/Manager/ViewDropouts.css';

function ViewDropouts() {
    const { schoolId } = useParams();
    const [dropouts, setDropouts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedDate, setSelectedDate] = useState(new Date());

    useEffect(() => {
        const fetchDropouts = async () => {
            try {
                const dropoutsSnapshot = await db
                    .collection("Schools")
                    .doc(schoolId)
                    .collection("Dropouts")
                    .get();
                const dropoutsData = dropoutsSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setDropouts(dropoutsData);
            } catch (error) {
                console.error("Error fetching dropouts:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchDropouts();
    }, [schoolId]);

    if (loading) return <Loading />;

    const filteredDropouts = selectedDate
        ? dropouts.filter(dropout => {
            const dropoutDate = new Date(dropout.date);
            return dropoutDate.toDateString() === selectedDate.toDateString();
        })
        : dropouts;

    const formatPhoneNumber = (phoneNumber) => {
        if (phoneNumber.startsWith("972")) {
            return "0" + phoneNumber.slice(3);
        }
        return phoneNumber;
    };

    return (
        <React.Fragment>
            <Head />
            <div className="date-picker-container">
                <DatePicker
                    selected={selectedDate}
                    onChange={date => setSelectedDate(date)}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="בחר תאריך"
                />
            </div>
            <div className="dropouts-list">
                {filteredDropouts.map(dropout => (
                    <div key={dropout.id} className="dropout-item" dir="rtl">
                        <p>שם התלמיד: {dropout.studentName}</p>
                        <p>תאריך: {dropout.date}</p>
                        <p>שעה: {dropout.time}</p>
                        <p>מספר טלפון: {formatPhoneNumber(dropout.phoneNumber)}</p>
                        <p>שם המורה: {dropout.teacherName}</p>
                    </div>
                ))}
            </div>
        </React.Fragment>
    );
}

export default ViewDropouts;

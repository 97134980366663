import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AddLayerModal from "../../../Functions/Manager/Data/AddNewLayer";
import AddSpecializationModal from "../../../Functions/Manager/Data/AddNewSpecialization";
import { SiGoogleclassroom } from "react-icons/si";
import { db } from "../../../Components/Firebase";
import '../../../Styles/Pages/Manager/School/Layers.css';
import Loading from "../../../Components/Loading";

function Layers() {
    const nav = useNavigate();
    const { userid, schoolid } = useParams();
    const [isLayerModalOpen, setLayerModalOpen] = useState(false);
    const [isSpecializationModalOpen, setSpecializationModalOpen] = useState(false);
    const [layers, setLayers] = useState([]);
    const [specializations, setSpecializations] = useState([]);
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const fetchLayersAndSpecializations = async () => {
            try {
                const layersSnapshot = await db.collection("Schools").doc(schoolid).collection("Layers").get();
                const layersData = layersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setLayers(layersData);

                const specializationsSnapshot = await db.collection("Schools").doc(schoolid).collection("Specializations").get();
                const specializationsData = specializationsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setSpecializations(specializationsData);
                setLoading(false)
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchLayersAndSpecializations();
    }, [schoolid, layers, specializations]);

    const handleDeleteSpecialization = async (specializationId) => {
        if (window.confirm("אתה בטוח שאתה רוצה למחוק את ההתמחות?")) {
            try {
                await db.collection("Schools").doc(schoolid).collection("Specializations").doc(specializationId).delete();
                setSpecializations(specializations.filter(specialization => specialization.id !== specializationId)); 
            } catch (error) {
                console.error("Error deleting specialization:", error);
            }
        }
    };
    
    if(loading) return <Loading/>

    return (
        <React.Fragment>
            <div className="Layers">
                <SiGoogleclassroom color="#fff" size={50} />
                <h1>שכבות וכיתות/ התמחות</h1>
                <p>נא להוסיף את השכבות וכיתות בית הספר וההתמחויות</p>
                <button onClick={() => nav(`/CheckUser/${userid}`)}>דף הבית</button>
            </div>

            <div className="LayersFun" dir="rtl">
                <div className="LayersContent">
                    <div className="DTop">
                        <h1>שכבות</h1>
                        <button onClick={() => setLayerModalOpen(true)}>להוסיף שכבות</button>
                    </div>
                    <ul>
                        {layers.map(layer => (
                            <li key={layer.id} className="layer-item">
                                {layer.name}
                                <button onClick={() => nav(`/Manager/${userid}/SchoolMain/${schoolid}/Layers/${layer.id}`)} className="view-button">פרטים</button>
                            </li> 
                        ))}
                    </ul>
                </div>

                <div className="specializations">
                    <div className="DTop">
                        <h1>התמחויות</h1>
                        <button onClick={() => setSpecializationModalOpen(true)}>להוסיף התמחויות</button>
                    </div>
                    <ul>
                        {specializations.map(specialization => (
                            <li key={specialization.id} className="specialization-item">
                                {specialization.name}
                                <button 
                                    onClick={() => handleDeleteSpecialization(specialization.id)} 
                                    className="delete-button">למחוק
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            {isLayerModalOpen && <AddLayerModal schoolid={schoolid} onClose={() => setLayerModalOpen(false)} />}
            {isSpecializationModalOpen && <AddSpecializationModal schoolid={schoolid} onClose={() => setSpecializationModalOpen(false)} />}
        </React.Fragment>
    );
}

export default Layers;

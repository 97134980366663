import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../Components/Firebase";
import Loading from "../../../Components/Loading";
import EditStudent from "./EditStudent";
import '../../../Functions/Styles/Manager/Data/FetchStudents.css';
import { FaPen } from "react-icons/fa";

function FetchStudents() {
    const { teacherid, schoolId, classid } = useParams();
    const [students, setStudents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    useEffect(() => {
        const fetchStudents = async () => {
            try {
                const studentsCollection = collection(db, "Schools", schoolId, "Students");
                const q = query(studentsCollection, where("class", "==", classid));
                const studentsSnapshot = await getDocs(q);
                const studentsList = studentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setStudents(studentsList);
            } catch (error) {
                console.error("Error fetching students data: ", error);
                setError("Error loading students data.");
            } finally {
                setLoading(false);
            }
        };

        fetchStudents();
    }, [teacherid, schoolId, classid, students]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredStudents = students.filter(student =>
        `${student.firstName} ${student.lastName}`.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleEditClick = (student) => {
        setSelectedStudent(student);
        setIsEditModalOpen(true);
    };

    const closeEditModal = () => {
        setIsEditModalOpen(false);
        setSelectedStudent(null);
    };

    if (loading) return <Loading />;
    if (error) return <h1>{error}</h1>;

    return (
        <React.Fragment>
            <div className="search-container">
                <input
                    type="text"
                    placeholder="חפש תלמידים לפי שם"
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
            </div>
            <div className="FS">
                <div className="StudentsList">
                    {filteredStudents.length > 0 ? (
                        filteredStudents.map((student) => (
                            <div key={student.id} className="student-item">
                                <h3>{student.firstName} {student.lastName}</h3>
                                <h3>{student.birthday}</h3>
                                <h3>{student.gender}</h3>
                                <h3>{student.dadName}</h3>
                                <h3>{student.momName}</h3>
                                {student.phoneNumbers && student.phoneNumbers.length > 0 ? (
                                    <div>
                                        {student.phoneNumbers.map((phone, idx) => (
                                            <p key={idx}>{phone}</p>
                                        ))}
                                    </div>
                                ) : (
                                    <p>אין טלפונים זמינים</p>
                                )}
                                <button onClick={() => handleEditClick(student)}><FaPen/></button>
                            </div>
                        ))
                    ) : (
                        <p>כדי לצפות בתלמידים אנא הוסף תלמידים לכיתה זו</p>
                    )}
                </div>
                {isEditModalOpen && selectedStudent && (
                    <EditStudent 
                        studentId={selectedStudent.id} 
                        studentData={selectedStudent} 
                        onClose={closeEditModal} 
                        schoolId={schoolId} 
                        classid={classid} 
                    />
                )}
            </div>
        </React.Fragment>
    );
}

export default FetchStudents;

import React, { useEffect, useState } from "react";
import Head from "../../Components/Head";
import { useNavigate, useParams } from "react-router-dom";
import { auth } from "../../Components/Firebase";
import FetchCurrentClass from "../../Functions/Teacher/Data/FetchCurrentClass";
import FetchCurrentStudents from "../../Functions/Teacher/Data/FetchCurrentStudents";

import '../../Styles/Pages/Teacher/TeacherHome.css';
import CheckingStudentAttendance from "../../Functions/Both/CheckingStudentAttendance";

function TeacherHome() {
    const nav = useNavigate();
    const { userid } = useParams();
    const [isAttendancePopupOpen, setIsAttendancePopupOpen] = useState(false);

    useEffect(() => {
        const checkUser = async () => {
            const user = auth.currentUser;
            if (!user) {
                nav(`/CheckUser/${userid}`);
            }
        };
        checkUser();
    }, [nav, userid]);

    const openAttendancePopup = () => {
        setIsAttendancePopupOpen(true);
    };

    const closeAttendancePopup = () => {
        setIsAttendancePopupOpen(false);
    };

    return (
        <React.Fragment>
            <Head />
            <div className="TeacherFun">
                <div className="TeacherData" dir="rtl">
                    <div>
                        <h1>הכיתה הנוכחית</h1>
                        <FetchCurrentClass />
                    </div>
                    <div>
                        <h1>תלמידים בכיתה</h1>
                        <FetchCurrentStudents />
                    </div>
                    <div>
                        <h1>השיעור הבא</h1>
                    </div>
                </div>

                <div className="TeacherBtn" dir="rtl">
                    <button onClick={() => nav(`/Teacher/${userid}/StudentsList`)}>רשימת תלמידים</button>
                    <button onClick={openAttendancePopup}>בדיקת נוכחות תלמידים</button>
                    <button>הגדרות חשבון</button>
                </div>
            </div>

            {/* open popups */}
            
            {isAttendancePopupOpen && (
                <CheckingStudentAttendance 
                    isOpen={isAttendancePopupOpen} 
                    onClose={closeAttendancePopup} 
                />
            )}
        </React.Fragment>
    );
}

export default TeacherHome;

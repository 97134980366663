import React, { useState } from "react";
import Vec1 from '../../Images/Vector1.webp';
import '../../Styles/Pages/Auth/SchoolCode.css';
import Loading from '../../Components/Loading';
import { auth, db } from '../../Components/Firebase';
import { useNavigate } from "react-router-dom";

function SchoolCode() {
    const [schoolCode, setSchoolCode] = useState("");
    const [schoolName, setSchoolName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const nav = useNavigate();

    const convertToIsraeliFormat = (phoneNumber) => {
        const digitsOnly = phoneNumber.replace(/\D/g, '');
        if (digitsOnly.startsWith('0')) {
            return '+972' + digitsOnly.substring(1);
        }
        return phoneNumber;
    };

    const handleUserInfo = async () => {
        try {
            setErrorMessage("");
            const currentUser = auth.currentUser;
            if (!currentUser) {
                setErrorMessage("User not authenticated");
                return;
            }
            setLoading(true);

            if (!schoolCode.trim()) {
                setErrorMessage('אנא מלא את קוד בית הספר');
                setLoading(false);
                return;
            }
            if (!phoneNumber.trim()) {
                setErrorMessage('אנא מלא את מספר הטלפון שלך');
                setLoading(false);
                return;
            }

            const phoneNumberIsraeliFormat = convertToIsraeliFormat(phoneNumber);
            const schoolQuerySnapshot = await db.collection('Schools').where('Private_Code', '==', schoolCode).get();
            if (schoolQuerySnapshot.empty) {
                setErrorMessage('אנא בדוק את קוד בית הספר שלך');
                setLoading(false);
                return;
            }

            const schoolData = schoolQuerySnapshot.docs[0].data();
            setSchoolName(schoolData.School_Name);

            await db.collection('Teachers').doc(currentUser.uid).set({
                schoolName: schoolData.School_Name,
                phoneNumber: phoneNumberIsraeliFormat,
                status: 'זמין'
            }, { merge: true });

            nav(`/SchoolCode/${currentUser.uid}/TeacherRoll`);

        } catch (error) {
            console.error('Error handling user info:', error);
            setErrorMessage('Error handling user info');
        } finally {
            setLoading(false);
        }
    };

    if (loading) return <Loading />;

    return (
        <React.Fragment>
            <div className="TIM">
                <img src={Vec1} alt="Vec1" />
                <div className="Userinfo2">
                    <h1>אימות בית ספר</h1>
                    <div className="NewInfo2">
                        <input
                            placeholder="קוד בית ספר"
                            value={schoolCode}
                            onChange={(e) => setSchoolCode(e.target.value)}
                            required
                        />
                        {schoolName && <p>שם בית הספר: {schoolName}</p>}
                        <input
                            placeholder="מספר טלפון"
                            type="tel"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            required
                        />
                    </div>
                    {errorMessage && <p dir="rtl" className="error-message">{errorMessage}</p>}
                    <button onClick={handleUserInfo} disabled={loading} className="UserInfo-button">הבא</button>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SchoolCode;

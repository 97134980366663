import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../../Components/Firebase";
import '../../../Functions/Styles/Teacher/Data/FetchCurrentClass.css'
function FetchCurrentClass() {
    const { userid } = useParams();
    const [className, setClassName] = useState(null);
    const [classManagerName, setClassManagerName] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchClassData = async () => {
            try {
                const teacherDoc = await db.collection("Teachers").doc(userid).get();
                
                if (teacherDoc.exists) {
                    const teacherData = teacherDoc.data();
                    const classId = teacherData.currentClass;
                    const schoolId = teacherData.schoolName;

                    if (classId) {
                        const layersSnapshot = await db
                            .collection("Schools")
                            .doc(schoolId)
                            .collection("Layers")
                            .get();

                        let foundClass = false;
                        
                        for (const layerDoc of layersSnapshot.docs) {
                            const classDoc = await layerDoc.ref
                                .collection("Classes")
                                .doc(classId)
                                .get();

                            if (classDoc.exists) {
                                const classData = classDoc.data();
                                setClassName(classData.name);

                                if (classData.manager) {
                                    const managerDoc = await db.collection("Teachers").doc(classData.manager).get();
                                    if (managerDoc.exists) {
                                        const managerData = managerDoc.data();
                                        const fullName = `${managerData.firstName} ${managerData.lastName}`;
                                        setClassManagerName(fullName);
                                    }
                                }
                                foundClass = true;
                                break;
                            }
                        }

                        if (!foundClass) {
                            console.error("לא נמצא מסמך כיתה בכל שכבה");
                        }
                    } else {
                        console.error("לא נמצא מזהה כיתה נוכחית עבור מורה זה");
                    }
                } else {
                    console.error("לא נמצא מסמך מורה");
                }
            } catch (error) {
                console.error("שגיאה בטעינת הנתונים: ", error);
            } finally {
                setLoading(false);
            }
        };

        fetchClassData();
    }, [userid, classManagerName, className]);

    if (loading) return <p>טוען...</p>;

    return (
        <React.Fragment>
            <p className="CI">{className || "לא מוקצה"}</p>
            <p className="CI">{classManagerName || "לא מוקצה"}</p>
        </React.Fragment>
    );
}

export default FetchCurrentClass;

import React, { useEffect, useState } from "react";
import Head from "../../Components/Head";
import { useParams } from "react-router-dom";
import { db } from "../../Components/Firebase";
import Loading from "../../Components/Loading";

import '../../Styles/Pages/Teacher/CurrentStudentsList.css';

function CurrentStudentsList() {
    const { userid } = useParams();
    const [students, setStudents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [boysCount, setBoysCount] = useState(0);
    const [girlsCount, setGirlsCount] = useState(0);

    useEffect(() => {
        const fetchStudents = async () => {
            try {
                const teacherDoc = await db.collection("Teachers").doc(userid).get();
                
                if (teacherDoc.exists) {
                    const teacherData = teacherDoc.data();
                    const currentClass = teacherData.currentClass;
                    const schoolName = teacherData.schoolName;

                    if (currentClass) {
                        const studentsSnapshot = await db
                            .collection("Schools")
                            .doc(schoolName)
                            .collection("Students")
                            .where("class", "==", currentClass)
                            .get();

                        let boys = 0;
                        let girls = 0;

                        const studentsData = studentsSnapshot.docs.map(doc => {
                            const data = doc.data();
                            if (data.gender === "זכר") boys += 1;
                            else if (data.gender === "נקבה") girls += 1;
                            
                            return {
                                id: doc.id,
                                firstName: data.firstName,
                                lastName: data.lastName,
                                gender: data.gender,
                                birthday: data.birthday,
                                dadName: data.dadName,
                                momName: data.momName,
                                phone: data.phoneNumbers || []
                            };
                        });

                        setStudents(studentsData);
                        setBoysCount(boys);
                        setGirlsCount(girls);
                    } else {
                        console.error("No current class ID found for this teacher");
                    }
                } else {
                    console.error("Teacher document not found");
                }
            } catch (error) {
                console.error("Error fetching students: ", error);
            } finally {
                setLoading(false);
            }
        };

        fetchStudents();
    }, [userid]);

    if (loading) return <Loading />;

    return (
        <React.Fragment>
            <Head />

            <div className="students-list">
            <h1>בנים: {boysCount} | בנות: {girlsCount}</h1>
                {students.length > 0 ? (
                    students.map(student => (
                        <div key={student.id} className="student-card">
                            <p className="student-name">{student.firstName}</p>
                            <p className="student-lastName">{student.lastName}</p>
                            <p className="student-gender">{student.gender}</p>
                            <p className="student-birthday">{student.birthday}</p>
                            <p className="student-dadName">{student.dadName}</p>
                            <p className="student-momName">{student.momName}</p>
                            <div className="student-phones">
                                <div className="phone-numbers">
                                    {student.phone.map((number, index) => (
                                        <p key={index} className="phone-number">{number}</p>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No students found for this class.</p>
                )}
            </div>
        </React.Fragment>
    );
}

export default CurrentStudentsList;

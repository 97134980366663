import React, { useState, useEffect } from "react";
import Head from "../../../Components/Head";
import '../../../Styles/Pages/Manager/School/PluginSettings.css';
import { useParams } from "react-router-dom";
import { db } from "../../../Components/Firebase";

function PluginSettings() {
    const { schoolid } = useParams();

    const [screenLanguage, setScreenLanguage] = useState("hebrew");
    const [screenNotes, setScreenNotes] = useState("");
    const [tabletLanguage, setTabletLanguage] = useState("hebrew");
    const [tabletNotes, setTabletNotes] = useState("");

    useEffect(() => {
        
        const fetchSettings = async () => {
            try {
                const doc = await db.collection("Schools").doc(schoolid).get();
                if (doc.exists) {
                    const data = doc.data();
                    if (data.screenSettings) {
                        setScreenLanguage(data.screenSettings.language || "hebrew");
                        setScreenNotes(data.screenSettings.notes || "");
                    }
                    if (data.tabletSettings) {
                        setTabletLanguage(data.tabletSettings.language || "hebrew");
                        setTabletNotes(data.tabletSettings.notes || "");
                    }
                }
            } catch (error) {
                console.error("Error fetching settings:", error);
                alert("Failed to load settings. Please try again.");
            }
        };

        fetchSettings();
    }, [schoolid]);

    const handleSaveScreen = async () => {
        try {
            await db.collection("Schools").doc(schoolid).set({
                screenSettings: {
                    language: screenLanguage,
                    notes: screenNotes,
                }
            }, { merge: true });
        } catch (error) {
            console.error("Error saving screen settings:", error);
            alert("Failed to save screen settings. Please try again.");
        }
    };

    const handleSaveTablet = async () => {
        try {
            await db.collection("Schools").doc(schoolid).set({
                tabletSettings: {
                    language: tabletLanguage,
                    notes: tabletNotes,
                }
            }, { merge: true });
        } catch (error) {
            console.error("Error saving tablet settings:", error);
            alert("Failed to save tablet settings. Please try again.");
        }
    };

    const handleDeleteScreenNotes = async () => {
        try {
            await db.collection("Schools").doc(schoolid).set({
                screenSettings: {
                    notes: ""
                }
            }, { merge: true });
            setScreenNotes("");
        } catch (error) {
            console.error("Error deleting screen notes:", error);
            alert("Failed to delete screen notes. Please try again.");
        }
    };

    const handleDeleteTabletNotes = async () => {
        try {
            await db.collection("Schools").doc(schoolid).set({
                tabletSettings: {
                    notes: ""
                }
            }, { merge: true });
            setTabletNotes("");
        } catch (error) {
            console.error("Error deleting tablet notes:", error);
            alert("Failed to delete tablet notes. Please try again.");
        }
    };

    return (
        <React.Fragment>
            <Head />

            <div className="Settings" dir="rtl">
                <h1>הגדרות מסך</h1>

                <div className="SettingsFun">
                    <label htmlFor="screen-language">שפת מסך:</label>
                    <select
                        id="screen-language"
                        name="screenLanguage"
                        value={screenLanguage}
                        onChange={(e) => setScreenLanguage(e.target.value)}
                    >
                        <option value="hebrew">עברית</option>
                        <option value="arabic">ערבית</option>
                    </select>

                    <label htmlFor="screen-notes">הערות:</label>
                    <textarea
                        id="screen-notes"
                        name="screenNotes"
                        placeholder="הכנס הערות כאן..."
                        value={screenNotes}
                        onChange={(e) => setScreenNotes(e.target.value)}
                    />

                    <div className="button-group">
                        <button className="save-button" onClick={handleSaveScreen}>שמור</button>
                        <button className="delete-button" onClick={handleDeleteScreenNotes}>מחק הערות</button>
                    </div>
                </div>
            </div>

            <div className="Settings" dir="rtl">
                <h1>הגדרות הטאבלט</h1>

                <div className="SettingsFun">
                    <label htmlFor="tablet-language">שפת טאבלט:</label>
                    <select
                        id="tablet-language"
                        name="tabletLanguage"
                        value={tabletLanguage}
                        onChange={(e) => setTabletLanguage(e.target.value)}
                    >
                        <option value="hebrew">עברית</option>
                        <option value="arabic">ערבית</option>
                    </select>

                    <label htmlFor="tablet-notes">הערות:</label>
                    <textarea
                        id="tablet-notes"
                        name="tabletNotes"
                        placeholder="הכנס הערות כאן..."
                        value={tabletNotes}
                        onChange={(e) => setTabletNotes(e.target.value)}
                    />

                    <div className="button-group">
                        <button className="save-button" onClick={handleSaveTablet}>שמור</button>
                        <button className="delete-button" onClick={handleDeleteTabletNotes}>מחק הערות</button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default PluginSettings;

import React, { useState } from "react";
import '../../Styles/Pages/Auth/CreateAccount.css';
import Vec2 from '../../Images/Vector2.webp';
import { FaCamera } from 'react-icons/fa';
import { auth, db, storage } from "../../Components/Firebase";
import Loading from "../../Components/Loading";
import { useNavigate } from "react-router-dom";

function CreateAccount() {
    const [userImage, setUserImage] = useState(null);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const nav = useNavigate()

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setUserImage(file); 
            };
            reader.readAsDataURL(file);
        }
    };

    const translateErrorToHebrew = (error) => {
        switch (error.code) {
            case "auth/email-already-in-use":
                return "כתובת דוא״ל זו כבר בשימוש.";
            case "auth/invalid-email":
                return "כתובת דוא״ל לא תקינה.";
            case "auth/weak-password":
                return "הסיסמה חלשה מדי. בחר סיסמה חזקה יותר.";
            case "auth/user-not-found":
                return "המשתמש לא נמצא.";
            case "auth/wrong-password":
                return "הסיסמה שגויה.";
            default:
                return "אירעה שגיאה בעת ההרשמה.";
        }
    };

    const handleSignUp = async () => {
        setLoading(true);
        setErrorMessage("");
        try {
            const userCredential = await auth.createUserWithEmailAndPassword(email, password);
            const user = userCredential.user;

            let imageUrl = "";
            if (userImage) {
                const imageRef = storage.ref(`Teachers/Userimage/${user.uid}/profile.jpg`);
                await imageRef.put(userImage);
                imageUrl = await imageRef.getDownloadURL();
            }

            await db.collection("Teachers").doc(user.uid).set({
                firstName: firstName,
                lastName: lastName,
                email: email,
                imageUrl: imageUrl,
                createdAt: new Date(),
            });

            await user.sendEmailVerification();

            nav('/VerifyEmail')
        } catch (error) {
            const translatedMessage = translateErrorToHebrew(error);
            setErrorMessage(translatedMessage);
        } finally {
            setLoading(false);
        }
    };

    if (loading) return <Loading />;

    return (
        <React.Fragment>
            <div className="CAM">
                <img src={Vec2} alt="Vec" />
                <div className="Userinfo">
                    <h1>רישום</h1>

                    <div className="userimage-container">
                        {userImage ? (
                            <img src={URL.createObjectURL(userImage)} alt="Preview" className="userimage-preview" />
                        ) : (
                            <>
                                <input
                                    type="file"
                                    id="userimage"
                                    className="userimage-input"
                                    accept="image/*"
                                    onChange={handleImageChange}
                                />
                                <label htmlFor="userimage" className="userimage-label">
                                    <FaCamera className="camera-icon" />
                                </label>
                            </>
                        )}
                    </div>

                    <div className="NewInfo">
                        <input
                            placeholder="שם פרטי"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                        />
                        <input
                            placeholder="שם משפחה"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                        />
                        <input
                            placeholder="דואר אלקטרוני"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <input
                            placeholder="סיסמה"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>

                    {errorMessage && <p dir="rtl" className="error-message">{errorMessage}</p>}

                    <button onClick={handleSignUp} disabled={loading} className="signup-button">רישום</button>
                </div>
            </div>
        </React.Fragment>
    );
}

export default CreateAccount;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../../Components/Firebase";

function FetchDropoutsCount() {
    const { userid } = useParams();
    const [dropoutsCount, setDropoutsCount] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchTodayDropouts = async () => {
            try {
                const today = new Date().toLocaleDateString();
                const teacherDoc = await db.collection("Teachers").doc(userid).get();
                if (teacherDoc.exists) {
                    const schoolName = teacherDoc.data().schoolName;
                    const dropoutsSnapshot = await db
                        .collection("Schools")
                        .doc(schoolName)
                        .collection("Dropouts")
                        .where("date", "==", today)
                        .get();
                    setDropoutsCount(dropoutsSnapshot.size);
                } else {
                    console.error("Teacher document not found");
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchTodayDropouts();
    }, [userid, dropoutsCount]);

    if (loading) return <p>טוען...</p>;

    return (
        <React.Fragment>
            <h1 className="SC">{dropoutsCount}</h1>
        </React.Fragment>
    );
}

export default FetchDropoutsCount;

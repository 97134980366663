import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../Components/Firebase";
import Loading from "../../Components/Loading";
import Vec5 from '../../Images/Vector5.jpg';
import '../../Styles/Pages/Auth/CheckUser.css';

function CheckUser() {
    const navigate = useNavigate();
    const { userid } = useParams();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const checkUserRole = async () => {
            try {
                setLoading(true);

                const userRef = doc(db, "Teachers", userid);
                const userDoc = await getDoc(userRef);

                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    const { roll } = userData;

                    if (roll === "Manager" || roll === "Secretary") {
                        navigate(`/Manager/${userid}`);
                    } else if (roll === "Teacher") {
                        navigate(`/Teacher/${userid}`);
                    }
                } else {
                    console.error("המשתמש לא נמצא");
                }
            } catch (error) {
                console.error("שגיאה בבדיקת תפקיד המשתמש: ", error);
            } finally {
                setLoading(false);
            }
        };

        checkUserRole();
    }, [navigate, userid]);

    if (loading) return <Loading/>;

    return (
        <React.Fragment>
            <div className="CheckUser">
                <img src={Vec5} alt="CheckUser"/>
                    <h1>אופס, יש לנו בעיה בבקשה נסה שוב מאוחר יותר</h1>
                <button onClick={() => {navigate('/')}}>לדף הבית</button>
            </div>
        </React.Fragment>
    );
}

export default CheckUser;

import React, { useState } from "react";
import Head from "../../../Components/Head";
import '../../../Styles/Pages/Manager/Students/ClassData.css';
import FetchStudents from "../../../Functions/Manager/Data/FetchStudents";
import StudentsInClassCount from "../../../Functions/Manager/Data/StudentsInClassCount";
import SortStudentsGender from "../../../Functions/Manager/Data/SortStudentsGender";
import AddNewStudent from "../../../Functions/Manager/Data/AddNewStudent";
import { useNavigate, useParams } from "react-router-dom";
import { FaPlus } from "react-icons/fa";

function ClassData() {
    const { userid, schoolId, classid, layerid } = useParams();
    const [showAddStudentModal, setShowAddStudentModal] = useState(false);
    const nav = useNavigate()

    const openAddStudentModal = () => {
        setShowAddStudentModal(true);
    };

    const closeAddStudentModal = () => {
        setShowAddStudentModal(false);
    };

    return (
        <React.Fragment>
            <Head />

            <div className="ClassDataFun">
                <div>
                    <h1>סה"כ תלמידים</h1>
                    <StudentsInClassCount />
                </div>

                <div>
                    <h1>מין</h1>
                    <SortStudentsGender />
                </div>

                <div>
                    <h1>הוסף תלמיד חדש</h1>
                    <button onClick={openAddStudentModal}><FaPlus size={30}/> להוסיף ידנית</button>
                    <button onClick={() => nav(`/Manager/${userid}/${schoolId}/ManageStudents/${layerid}/Classes/${classid}/AddFile`)}><FaPlus size={30}/> הוסף בדרך הקובץ</button>
                </div>
            </div>

            <FetchStudents />

            {showAddStudentModal && (
                <AddNewStudent
                    onClose={closeAddStudentModal}
                    schoolId={schoolId}
                    classid={classid}
                />
            )}
        </React.Fragment>
    );
}

export default ClassData;

import React, { useState } from "react";
import { db } from "../../../Components/Firebase";
import "../../Styles/Manager/Data/AddNewClass.css";

function AddNewClass({ onClose, schoolid, layerid }) {
    const [className, setClassName] = useState("");

    const handleSave = async () => {
        if (className.trim()) {
            try {
                await db.collection("Schools").doc(schoolid).collection("Layers").doc(layerid).collection("Classes").add({
                    name: className,
                    createdAt: new Date(),
                });
                console.log("Class added:", className);
                onClose();
            } catch (error) {
                console.error("Error adding Class: ", error);
            }
        }
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>הוסף כיתה חדשה</h2>
                <input
                    type="text"
                    placeholder="שם כיתה"
                    value={className}
                    onChange={(e) => setClassName(e.target.value)}
                    className="modal-input"
                />
                <div className="modal-actions">
                    <button className="modal-button cancel" onClick={onClose}>ביטול</button>
                    <button className="modal-button save" onClick={handleSave}>שמור</button>
                </div>
            </div>
        </div>
    );
}

export default AddNewClass;

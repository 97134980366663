import React from "react";
import Head from "../../Components/Head";
import FetchTeachers from "../../Functions/Manager/Data/FetchTeachers";

function ViewTeachers () {

    return (

        <React.Fragment>

            <Head/>

            <FetchTeachers/>

        </React.Fragment>
    )
}

export default ViewTeachers
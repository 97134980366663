import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db } from "../../../Components/Firebase";
import Head from "../../../Components/Head";
import '../../../Styles/Pages/Manager/School/SchoolMain.css';
import Loading from "../../../Components/Loading";

function SchoolMain() {
  const { userid, schoolid } = useParams();
  const [schoolData, setSchoolData] = useState(null);

  const nav = useNavigate()

  useEffect(() => {
    const fetchSchoolData = async () => {
      try {
        const schoolDoc = await db.collection("Schools").doc(schoolid).get();
        if (schoolDoc.exists) {
          setSchoolData(schoolDoc.data());
        } else {
          console.log("בית ספר לא נמצא");
        }
      } catch (error) {
        console.error("שגיאה בעת הבאת נתוני בית הספר: ", error);
      }
    };

    fetchSchoolData();
  }, [schoolid]);

  return (
    <React.Fragment>
      <Head />

      <div className="SM">
        {schoolData ? (
          <div className="SchoolInfo">
            <p>שם בית הספר: {schoolData.School_Name}</p>
            <p>קוד פרטי: {schoolData.Private_Code}</p>
            <p>מנהל בית הספר: {schoolData.School_Principal}</p>
            <p>סוג בית הספר: {schoolData.School_Type}</p>
            <p>כתובת: {schoolData.School_Location}</p>
            <p>קוד מסך: {schoolData.Screen_Code}</p>
            <p>קוד טאבלט: {schoolData.Tab_Code}</p>
          </div>
        ) : (
          <Loading/>
        )}

        <div className="SMFun">
          <button onClick={() => nav(`/Manager/${userid}/SchoolMain/${schoolid}/Layers`)}>שכבות וכיתות / התמחות</button>
          <button style={{ background: '#ddd' }} disabled>מידע למורים</button>
          <button style={{ background: '#ddd' }} disabled>רשימת ההורים</button>
          <button onClick={() => nav(`/Manager/${userid}/SchoolMain/${schoolid}/PluginSettings`)}>הגדרות מסך / טאבלט</button>
          <button onClick={() => nav(`/Manager/${userid}/SchoolMain/${schoolid}/MessagesSettings`)}>הגדרות הודעות</button>
          <button style={{background: 'red'}}>לדווח על בעיה</button>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SchoolMain;

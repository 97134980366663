import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../../Components/Firebase";
import AddNewClass from "../../../Functions/Manager/Data/AddNewClass";
import Select from "react-select";
import '../../../Styles/Pages/Manager/School/Classes.css';
import Loading from "../../../Components/Loading";

function Classes() {
    const { schoolid, layerid } = useParams();
    const [loading, setLoading] = useState(false);
    const [isClassesModalOpen, setClassesModalOpen] = useState(false);
    const [classes, setClasses] = useState([]);
    const [layerName, setLayerName] = useState("");
    const [teachers, setTeachers] = useState([]);

    const fetchLayerNameAndClasses = async () => {
        setLoading(true);
        try {
            const layerDoc = await db.collection("Schools")
                .doc(schoolid)
                .collection("Layers")
                .doc(layerid)
                .get();

            if (layerDoc.exists) {
                setLayerName(layerDoc.data().name);

                const classesSnapshot = await db.collection("Schools")
                    .doc(schoolid)
                    .collection("Layers")
                    .doc(layerid)
                    .collection("Classes")
                    .get();

                const classesList = classesSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setClasses(classesList);
            } else {
                console.log("No such layer!");
            }
        } catch (error) {
            console.error("Error fetching layer or classes: ", error);
        }
        setLoading(false);
    };

    const fetchTeachers = async () => {
        try {
            const teachersSnapshot = await db
                .collection("Teachers")
                .where("schoolName", "==", schoolid)
                .get();

            const teachersList = teachersSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
            setTeachers(teachersList);
        } catch (error) {
            console.error("Error fetching teachers: ", error);
        }
    };

    const handleSelectManager = async (classId, teacherId) => {
        try {
            await db.collection("Schools")
                .doc(schoolid)
                .collection("Layers")
                .doc(layerid)
                .collection("Classes")
                .doc(classId)
                .update({
                    manager: teacherId
                });
            console.log(`Manager updated for class ${classId}: ${teacherId}`);
        } catch (error) {
            console.error("Error updating class manager: ", error);
        }
    };

    useEffect(() => {
        fetchLayerNameAndClasses();
        fetchTeachers();
    }, [schoolid, layerid]);

    if (loading) return <Loading />;

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
        }),
        singleValue: (provided) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
        }),
    };

    const teacherOptions = teachers.map((teacher) => ({
        value: teacher.id,
        label: (
            <div style={{ display: "flex", alignItems: "center", gap: '10px', fontFamily: 'MainFont' }}>
                <img
                    src={teacher.imageUrl}
                    alt={teacher.firstName}
                    style={{ width: "30px", height: "30px", borderRadius: "50%", marginRight: "10px" }}
                />
                {teacher.firstName} {teacher.lastName}
            </div>
        ),
    }));

    return (
        <React.Fragment>
            <div className="Classes">
                <h1>הכיתות ב {layerName}</h1>
                <p>בבקשה הוסף כיתות ל {layerName}</p>
                <button onClick={() => setClassesModalOpen(true)}>הוסף כיתה חדשה</button>
            </div>

            <div className="ClassesView">
                {classes.length > 0 ? (
                    <div className="ClassesCon">
                        {classes.map((cls) => {
                            const defaultManager = teachers.find(teacher => teacher.id === cls.manager);

                            return (
                                <div key={cls.index} className="ClassInfo">
                                    <h1>{cls.name}</h1>

                                    <div className="TeacherSelection">
                                        <label htmlFor={`teacher-select-${cls.id}`}>בחר מנהל:</label>
                                        <Select
                                            className="Select"
                                            styles={customStyles}
                                            options={teacherOptions}
                                            defaultValue={defaultManager ? {
                                                value: defaultManager.id,
                                                label: (
                                                    <div style={{ display: "flex", alignItems: "center", gap: '10px', fontFamily: 'MainFont' }}>
                                                        <img
                                                            src={defaultManager.imageUrl}
                                                            alt={defaultManager.firstName}
                                                            style={{ width: "30px", height: "30px", borderRadius: "50%", marginRight: "10px" }}
                                                        />
                                                        {defaultManager.firstName} {defaultManager.lastName}
                                                    </div>
                                                )
                                            } : null}
                                            onChange={(selectedOption) =>
                                                handleSelectManager(cls.id, selectedOption.value)
                                            }
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <p>אין כיתות נוספות</p>
                )}
            </div>

            {isClassesModalOpen && (
                <AddNewClass
                    schoolid={schoolid}
                    layerid={layerid}
                    onClose={() => setClassesModalOpen(false)}
                />
            )}
        </React.Fragment>
    );
}

export default Classes;

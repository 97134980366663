import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../Components/Firebase";

function SortStudentsGender() {
    const { userid, schoolId, classid } = useParams();
    const [maleCount, setMaleCount] = useState(0);
    const [femaleCount, setFemaleCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchAndCountStudentsByGender = async () => {
            try {
                const studentsCollection = collection(db, "Schools", schoolId, "Students");
                const q = query(studentsCollection, where("class", "==", classid));
                const studentsSnapshot = await getDocs(q);
                const studentsList = studentsSnapshot.docs.map(doc => doc.data());

                const maleCount = studentsList.filter(student => student.gender === "זכר").length;
                const femaleCount = studentsList.filter(student => student.gender === "נקבה").length;

                setMaleCount(maleCount);
                setFemaleCount(femaleCount);
            } catch (error) {
                console.error("Error fetching and counting students: ", error);
                setError("שגיאה בטעינת נתוני התלמידים.");
            } finally {
                setLoading(false);
            }
        };

        fetchAndCountStudentsByGender();
    }, [userid, schoolId, classid, maleCount, femaleCount]);

    if (loading) return <p>טוען...</p>;
    if (error) return <h1>{error}</h1>;

    return (
        <React.Fragment>
                
                <h2 style={{color: '#fff' , fontFamily: 'MainFont' , marginTop: '10px'}}>מספר תלמידים : {maleCount}</h2>
                <h2 style={{color: '#fff' , fontFamily: 'MainFont' , marginTop: '10px'}}>מספר תלמידות : {femaleCount}</h2>

        </React.Fragment>
    );
}

export default SortStudentsGender;

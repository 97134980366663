import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../Components/Firebase";
import Loading from "../../../Components/Loading";
import Logo from '../../../Images/Logo.webp';
import '../../Styles/Manager/Data/FetchTeacherData.css';

function FetchTeacherData() {
    const { teacherid } = useParams();
    const [teacherData, setTeacherData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchTeacherData = async () => {
            try {
                const teacherRef = doc(db, "Teachers", teacherid);
                const teacherDoc = await getDoc(teacherRef);

                if (teacherDoc.exists()) {
                    setTeacherData(teacherDoc.data());
                } else {
                    setError("המשתמש לא נמצא");
                }
            } catch (error) {
                console.error("שגיאה בטעינת נתוני המורה: ", error);
                setError("שגיאה בטעינת הנתונים");
            } finally {
                setLoading(false);
            }
        };

        fetchTeacherData();
    }, [teacherid,teacherData]);

    if (loading) return <Loading />;
    if (error) return <h1>{error}</h1>;

    return (
        <React.Fragment>
            {teacherData && (
                <div className="TeacherProfile">
                    <div className="TeacherPinfo">
                        <img 
                            src={teacherData.imageUrl || Logo} 
                            alt="Teacher Profile" 
                        />
                        <h2>{teacherData.firstName} {teacherData.lastName}</h2>
                        <p>סטטוס: {teacherData.status}</p>
                    </div>

                    <div className="TeacherPdata">
                        <p>אימייל: {teacherData.email}</p>
                        <p>בית ספר: {teacherData.schoolName}</p>
                        <p>טלפון: {teacherData.phoneNumber}</p>
                        <p>שכבות: {Array.isArray(teacherData.gradeLevels) ? teacherData.gradeLevels.join(', ') : 'אין'}</p>
                        <p>התמחויות: {Array.isArray(teacherData.specializations) ? teacherData.specializations.join(', ') : 'אין'}</p>
                        <p>תאריך יצירה: {teacherData.createdAt ? teacherData.createdAt.toDate().toLocaleDateString() : 'תאריך לא זמין'}</p>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}

export default FetchTeacherData;

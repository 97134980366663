import React, { useState, useEffect } from "react";
import { db } from "../../../Components/Firebase";
import { useParams } from "react-router-dom";

import '../../Styles/Manager/Data/TeachersCountAndSort.css'

function TeachersCountAndSort() {
  const { userid } = useParams();
  const [statusCounts, setStatusCounts] = useState({
    available: 0,
    inClass: 0,
    unavailable: 0,
  });

  useEffect(() => {
    const fetchSchoolAndTeacherStatus = async () => {
      try {
        const userDoc = await db.collection("Teachers").doc(userid).get();
        if (userDoc.exists) {
          const userData = userDoc.data();
          const schoolname = userData.schoolName;

          const teachersCollection = await db
            .collection("Teachers")
            .where("schoolName", "==", schoolname)
            .get();

          let availableCount = 0;
          let inClassCount = 0;
          let unavailableCount = 0;

          teachersCollection.docs.forEach((doc) => {
            const teacher = doc.data();
            if (teacher.status === "זמין") {
              availableCount++;
            } else if (teacher.status === "בשיעור") {
              inClassCount++;
            } else if (teacher.status === "לא זמין") {
              unavailableCount++;
            }
          });

          setStatusCounts({
            available: availableCount,
            inClass: inClassCount,
            unavailable: unavailableCount,
          });
        }
      } catch (error) {
        console.error("Error fetching teachers' statuses: ", error);
      }
    };

    fetchSchoolAndTeacherStatus();
  }, [userid, statusCounts]);

  return (
    <React.Fragment>
      <p className="StatusP">זמינים: {statusCounts.available}</p>
      <p className="StatusP">בשיעור: {statusCounts.inClass}</p>
      <p className="StatusP">לא זמינים: {statusCounts.unavailable}</p>
      <p className="StatusP">סך הכל: {statusCounts.unavailable + statusCounts.inClass + statusCounts.available}</p>
    </React.Fragment>
  );
}

export default TeachersCountAndSort;

import React, { useEffect, useState } from "react";
import Head from "../../../Components/Head";
import { db } from "../../../Components/Firebase";
import '../../../Styles/Pages/Manager/Students/ManageStudents.css';
import { useParams, useNavigate } from "react-router-dom";
import Loading from "../../../Components/Loading";

function ManageStudents() {
    const [layers, setLayers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { schoolId, userid } = useParams();
    const nav = useNavigate()

    useEffect(() => {
        const fetchLayers = async () => {
            try {
                const layersSnapshot = await db.collection('Schools').doc(schoolId).collection('Layers').get();
                const layersData = await Promise.all(layersSnapshot.docs.map(async (doc) => {
                    const layer = doc.data();
                    const layerId = doc.id; 
                    const classesSnapshot = await db.collection('Schools').doc(schoolId).collection('Layers').doc(doc.id).collection('Classes').get();
                    const classCount = classesSnapshot.size;
                    return { ...layer, classCount, layerId  };
                }));
                setLayers(layersData);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch layers');
                setLoading(false);
            }
        };

        fetchLayers();
    }, [schoolId]);

    if (loading) {
        return <Loading />;
    }

    if (error) {
        return <p>{error}</p>;
    }

    return (
        <React.Fragment>

            <Head />

            <div className="ManageStudents">
                <div className="MSL">
                    {layers.map((layer, index) => (
                        <div key={index} className="layer-item">
                            <h3>שכבה: {layer.name}</h3>
                            <p>מספר כיתות: {layer.classCount}</p>

                            <button className="MSLB" onClick={() => nav(`/Manager/${userid}/${schoolId}/ManageStudents/${layer.layerId}/Classes`)}>לצפות בכיתות</button>
                        </div>
                    ))}
                </div>
            </div>
        </React.Fragment>
    );
}

export default ManageStudents;

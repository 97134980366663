import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../../Components/Firebase";

function FetchCurrentStudents() {
    const { userid } = useParams();
    const [studentCount, setStudentCount] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchStudentData = async () => {
            try {
                const teacherDoc = await db.collection("Teachers").doc(userid).get();
                
                if (teacherDoc.exists) {
                    const teacherData = teacherDoc.data();
                    const currentClass = teacherData.currentClass;
                    const schoolName = teacherData.schoolName;

                    if (currentClass) {
                        const studentsSnapshot = await db
                            .collection("Schools")
                            .doc(schoolName)
                            .collection("Students")
                            .where("class", "==", currentClass)
                            .get();

                        setStudentCount(studentsSnapshot.size);
                    } else {
                        console.error("No current class ID found for this teacher");
                    }
                } else {
                    console.error("Teacher document not found");
                }
            } catch (error) {
                console.error("Error fetching data: ", error);
            } finally {
                setLoading(false);
            }
        };

        fetchStudentData();
    }, [userid]);

    if (loading) return <p>טוען...</p>;

    return (
        <React.Fragment>
            <h1 className="SC">{studentCount}</h1>
        </React.Fragment>
    );
}

export default FetchCurrentStudents;
